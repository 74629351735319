import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Boka cykelplats på bussen' },
  loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'admin',
    data: { title: 'Bikecoach Manage' },
    canActivate: [MsalGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
