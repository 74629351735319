import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { ConfigurationService } from './configuration.service';

//Checkar om det är öppet cykelsäsong just nu.
@Injectable()
export class Maintenance implements CanActivate {
  constructor(private router: Router, private config: ConfigurationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.config.isUnderMaintenance) {
      this.router.navigate(['/maintenance']);
      return false;
    }
    else {
      return true;
    }
  }
}
