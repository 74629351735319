// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: window.location.origin + '/api/',


  regex: {
    numbers: '^[0-9]*$',
    time: '^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$'
  },

  zones: [1, 2, 3, 4],
  azureAD: {
    tenantId: '3081b6eb-204d-47f9-95d2-8a5994eb97ec',
    clientId: 'c07f578d-0ca7-40d8-9480-fb06746e2fcd',
    redirectUri: window.location.origin + '/admin',
    apiKey: '71d2a684-b8e0-495e-b762-0c3b74d05e00.a3dafdda-28b2-43f7-a94f-6f4894ac6024',
    endpoints: [
      { 'https://localhost:44375/api/': 'c07f578d-0ca7-40d8-9480-fb06746e2fcd' }
    ],
    storeAuthStateInCookie: true,  
    storage: 'sessionStorage'
  }

}


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
