import { BrowserModule} from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Maintenance } from './services/maintenance.service';
import { ConfigurationService } from './services/configuration.service';
import { environment } from '../environments/environment';
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import localeSv from '@angular/common/locales/sv';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeSv);

export function getADConfig() {
  return {
    tenant: environment.azureAD.tenantId,
    clientId: environment.azureAD.clientId,
    redirectUri: environment.azureAD.redirectUri,
    endpoints: environment.azureAD.endpoints,
    navigateToLoginRequestUrl: false,
    cacheLocation: environment.azureAD.storage,
  };
}


const initializerConfig = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.azureAD.clientId,
        redirectUri: environment.azureAD.redirectUri,
        navigateToLoginRequestUrl: false,
        authority: 'https://login.microsoftonline.com/' + environment.azureAD.tenantId,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: true      },

    }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        loginFailedRoute: "/",
        authRequest: {
          scopes: ['openid']
        }

      }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        ["admin", ['.default', 'openid', 'profile']],
        ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
        ["api/*/auth/*/", ['api://' + environment.azureAD.clientId + "/access"]],
        ["api/*/auth", ['api://' + environment.azureAD.clientId + "/access"]],
      ])
    }) 
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ConfigurationService, { provide: APP_INITIALIZER, useFactory: initializerConfig, multi: true, deps: [ConfigurationService] },
    Maintenance,
    { provide: LOCALE_ID, useValue: 'sv-SE' },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
