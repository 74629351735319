import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigurationService {

  private configuration: IServerConfiguration;
  baseApiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }
  //Hämtar konfiguration
  loadConfig() {
    return this.http.get<IServerConfiguration>(this.baseApiUrl + 'Configuration/MaintenanceStatus')
      .toPromise().then(result => {
        this.configuration = <IServerConfiguration>(result);
      }, error => console.error(error));
  }

  get isUnderMaintenance() {
    this.loadConfig();
    return this.configuration.isUnderMaintenance;
  }

  setStatus(status: Boolean) {
    return this.http.patch(this.baseApiUrl + 'Configuration/auth/MaintenanceStatus', status);
  }
}

export interface IServerConfiguration {
  isUnderMaintenance: boolean;
}
